<template>
  <KTCodePreview v-bind:title="'Danh sách các form POSM'">
    <template v-slot:toolbar>
      <b-dropdown
        size='sm'
        right
        split
        variant='primary'
        @click='routeCreatePosmForm'
        v-if="checkPermissions(['POSM_FORM_INSERT', 'POSM_FORM'])"
      >
        <template v-slot:button-content>
          <div class='font-weight-bolder'>
                <span>
                  <i style='font-size: 1rem' class='flaticon2-add-1'></i>Tạo
                  mới</span
                >
          </div>
        </template>
        <b-dropdown-item-button @click='routeImportExcel'
        ><i class='far fa-file-excel mr-2 text-success'></i>Import excel
        </b-dropdown-item-button>
      </b-dropdown>
    </template>
    <template v-slot:preview>
      <b-col cols='12' class='py-8 custom-card-body'>
        <b-table
          bordered
          :items='posmForms'
          :fields='fields'
          empty-text='Bạn chưa tạo form nào'
          :current-page='currentPage'
          per-page='10'
        >
          <template #cell(timeRange)='row'>
            <p>Từ: {{ row.item.timeRange[0] }}</p>
            <p>Đến: {{ row.item.timeRange[1] }}</p>
          </template>
          <template #cell(stores)='row'>
            <div
              v-if='isArray(row.item.stores) && row.item.stores.length > 0'
            >
              <div v-for='store in row.item.stores' :key='store.uuid'>
                <p class='mb-0'>{{ store.name }}</p>
                <p>Địa chỉ: {{ store.address }}</p>
                <br />
              </div>
            </div>
            <p v-else>{{ row.item.stores }}</p>
          </template>
          <template #cell(actions)='row'>
            <b-button
              variant='outline-primary'
              size='sm'
              @click='editPOSMFormHandler(row)'
            >
              <i class='fas fa-edit'></i>
            </b-button>
            <b-button
              variant='outline-danger'
              size='sm'
              @click='deletePOSMFormHandler(row)'
            >
              <i class='fas fa-trash'></i>
            </b-button>
          </template>
        </b-table>
        <b-pagination
          v-show='posmForms.length > 10'
          v-model='currentPage'
          :total-rows='posmForms.length'
          :per-page='10'
          aria-controls='my-table-product'
          align='right'
        ></b-pagination>
      </b-col>
    </template>
  </KTCodePreview>
</template>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { posmFormTableFields as fields } from '@/view/pages/posm/table-setup';
import ApiService from '@/core/services/api.service';
import { cmdUrl } from '@/utils/apiUrl';
import POSMFormTableData from '@/view/pages/posm/models/POSMFormTableData';
import { isArray } from 'lodash';
import { makeToastFaile, makeToastSuccess } from '@/utils/common';
import { SUCCESS_RESPONSE_STATUS } from '@/utils/constants';
import { checkPermission, checkPermissions } from '@/utils/saveDataToLocal';

export default {
  components: {
    KTCodePreview,
  },
  data() {
    return {
      fields,
      posmForms: [],
      currentPage: 1,
    };
  },
  methods: {
    checkPermissions,
    checkPermission,
    isArray,
    editPOSMFormHandler(row) {
      const uuid = row.item.uuid;
      this.$router.push({ name: 'posm-form-edit', params: { uuid } });
    },
    deletePOSMFormHandler(row) {
      const uuid = row.item.uuid;
      const confirmDelete = confirm('Bạn có chắc chắn muốn xóa Form này?');

      if (!confirmDelete) {
        return;
      }
      ApiService.delete(`${cmdUrl.POSMForm.root}/${uuid}`)
        .then(({ data }) => {
          if (data.status === SUCCESS_RESPONSE_STATUS) {
            makeToastSuccess('Xoá form POSM thành công');
            this.fetchPOSMForms();
          }
        })
        .catch((err) => {
          makeToastFaile(err.message);
        });
    },
    fetchPOSMForms(params) {
      ApiService.query(cmdUrl.POSMForm.root, {
        params: params ? params : { ...this.apiParams },
      })
        .then(({ data }) => {
          this.posmForms = data.data.map((posmForm) => {
            const td = new POSMFormTableData(
              posmForm.uuid,
              posmForm.name,
              posmForm.Campaign.name,
              [posmForm.Campaign.startDate, posmForm.Campaign.endDate],
              posmForm.createdBy,
              posmForm.stores,
            );
            td.formatTime();
            return td;
          });
        })
        .catch((err) => {
          makeToastFaile(err.message);
        });
    },
    routeCreatePosmForm() {
      this.$router.push({
        name: 'posm-form-create',
      });
    },
    routeImportExcel() {
      this.$router.push({
        name: 'posm-form-import-excel',
      });
    },
  },
  mounted() {
    this.fetchPOSMForms();
  },
};
</script>
